.bi-nav {
    font-size: 1.5rem;
}

.layout-header {
    // Metallic violet #5b0a91
    background-color: #5b0a91;
    background: linear-gradient(45deg, #5b0a91, #793ba4,#5e00a2, #a87bc3 );
    //animation: gradientShift 3s infinite alternate;
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}