/* Import Fonts */
@import 'Comfortaa/font.scss';
@import 'JosefinSans/font.scss';
@import 'Kanit/font.scss';
@import 'Lexend/font.scss';
@import 'Montserrat/font.scss';
@import 'MontserratAlternates/font.scss';
@import 'Nunito/font.scss';
@import 'NunitoSans/font.scss';
@import 'Prompt/font.scss';
@import 'Quicksand/font.scss';
@import 'Raleway/font.scss';
@import 'Roboto/font.scss';
@import 'TitilliumWeb/font.scss';
@import 'Urbanist/font.scss';


/* Import Icons */
@import 'icons/NotoEmoji/Regular/font.scss';
@import 'icons/NotoEmoji/Color/font.scss';
@import 'icons/MaterialIcons/Regular/font.scss';
@import 'icons/MaterialIcons/Outlined/font.scss';
@import 'icons/MaterialIcons/Rounded/font.scss';
@import 'icons/MaterialIcons/Sharp/font.scss';
@import 'icons/MaterialIcons/TwoTone/font.scss';
@import 'icons/MaterialSymbols/Outlined/font.scss';
@import 'icons/MaterialSymbols/Rounded/font.scss';
@import 'icons/MaterialSymbols/Sharp/font.scss';
