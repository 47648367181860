@mixin light-metallic-gradient {
    background: linear-gradient(90deg, #dfe6e9, #ffffff, #dfe6e9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1), -1px -1px 0px rgba(255, 255, 255, 0.3);
}

@mixin dark-metallic-gradient {
    background: linear-gradient(90deg, #2a2b2c, #000000, #2e2f30);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1), -1px -1px 0px rgba(255, 255, 255, 0.3);
}

@mixin purple-metallic-gradient {
    background: linear-gradient(90deg, #6f01b8, #ae1cf1, #8707ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1), -1px -1px 0px rgba(255, 255, 255, 0.3);
}


.logo {
    display: flex;
    align-items: center !important;
    text-decoration: none !important;
    &.logo-header {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
        font-size: 2.5rem;
        @include media-breakpoint-up(lg) {
            margin-right: auto !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
    .logo-img {
        width: 1em;
        height: 1em;
        margin-right: 0.2em
    }
    .logo-text {
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
        font-size: 1em;
        @include light-metallic-gradient;
    }
}